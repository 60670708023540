import React from 'react';

interface InputFieldPropsBase {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value: string | null;
  onChange: (value: string) => void;
  onBlur: () => void;
  error: boolean;
  helperText: string;
  containerClass?: string;
  containerErrorClass?: string;
  helperTextClass?: string;
  helperTextErrorClass?: string;
}

type InputFieldPropsInput = InputFieldPropsBase & {
  type: string;
  textarea?: never;
};

type InputFieldPropsTextArea = InputFieldPropsBase & {
  type?: never;
  textarea: true;
};

function InputField(props: InputFieldPropsInput): React.ReactElement;
function InputField(props: InputFieldPropsTextArea): React.ReactElement;

function InputField({
  id,
  name,
  type,
  placeholder,
  className,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  textarea,
  containerClass,
  containerErrorClass,
  helperTextClass,
  helperTextErrorClass,
}: InputFieldPropsInput | InputFieldPropsTextArea): React.ReactElement {
  return (
    <div
      className={containerClass + (error && containerErrorClass ? ' ' + containerErrorClass : '')}
    >
      {textarea ? (
        <textarea
          id={id}
          className={className}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
        />
      ) : (
        <input
          type={type}
          id={id}
          className={className}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
        />
      )}
      <span
        className={
          helperTextClass + (error && helperTextErrorClass ? ' ' + helperTextErrorClass : '')
        }
        style={{ visibility: !helperText ? 'hidden' : undefined }}
      >
        {helperText || 'x'}
      </span>
    </div>
  );
}

export default InputField;
