import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx } from '../../utils/utils';

import * as styles from './ImageAndTextSection.module.scss';

export const ImageAndTextSectionFragment = graphql`
  fragment ImageAndTextSection on SanityLpPersonalityQuizImageAndTextSection {
    content {
      image {
        asset {
          gatsbyImageData
        }
      }
      text
    }
  }
`;

export interface ImageAndTextSectionProps {
  content: Array<{
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    text: string;
  }>;
  className?: string;
}

const ImageAndTextSection = ({
  content,
  className,
}: ImageAndTextSectionProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.contentContainer}>
        {content.map((imageAndText, i) => (
          <div className={styles.imageAndTextContainer} key={i}>
            <div className={styles.imageContainer}>
              <GatsbyImage alt="" image={imageAndText.image.asset.gatsbyImageData} />
            </div>
            <p className={styles.text}>{replaceNewLinesWithBr(imageAndText.text)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageAndTextSection;
