import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import Button from './Button';
import { clsx } from '../../utils/utils';
import { Answer } from '../../templates/pages';

import * as styles from './QuestionScreen.module.scss';

export interface QuestionScreenProps {
  gatsbyImageAsset: IGatsbyImageData;
  mobileGatsbyImageAsset: IGatsbyImageData;
  currentQuestionNumber: number;
  totalQuestionsNumber: number;
  question: string;
  answersWithAnswerText: Array<[Answer, string]>;
  onAnswerPick: (answer: Answer) => void;
  className?: string;
}

const QuestionScreen = ({
  gatsbyImageAsset,
  mobileGatsbyImageAsset,
  currentQuestionNumber,
  totalQuestionsNumber,
  question,
  answersWithAnswerText,
  onAnswerPick,
  className,
}: QuestionScreenProps): React.ReactElement => {
  const [selectedAnswer, setSelectedAnswer] = useState<Answer | null>(null);

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.contentContainer}>
        <div className={styles.questionContainer}>
          <div className={styles.questionsNumber}>
            <span className={styles.currentQuestionNumber}>{currentQuestionNumber}</span>
            <span className={styles.totalQuestionsNumber}>/{totalQuestionsNumber}</span>
          </div>
          <h1 className={styles.question}>{question}</h1>
          <div className={styles.answersContainer}>
            {answersWithAnswerText.map(([answer, answerText], answerIndex) => (
              <Button
                secondary
                key={currentQuestionNumber + '-' + answerIndex + '-' + answer}
                className={clsx(styles.answer, selectedAnswer === answer && styles.selectedAnswer)}
                onClick={() => {
                  setSelectedAnswer(answer);
                  setTimeout(() => {
                    unstable_batchedUpdates(() => {
                      onAnswerPick(answer);
                      setSelectedAnswer(null);
                    });
                  });
                }}
              >
                {answerText}
              </Button>
            ))}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <GatsbyImage alt="" image={gatsbyImageAsset} className={styles.image} />
        </div>
        <div className={styles.mobileImageContainer}>
          <GatsbyImage alt="" image={mobileGatsbyImageAsset} className={styles.image} />
        </div>
      </div>
    </div>
  );
};

export default QuestionScreen;
