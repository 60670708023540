import React from 'react';
import { useGlobalState } from '../../state/globalStateContext';
import { useForm, useFormField } from '../../utils/forms';
import { clsx, getPersonalityFromAnswers } from '../../utils/utils';
import InputField from './InputField';
import { CgSpinner } from 'react-icons/cg';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './SubmitEmailScreen.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export interface SubmitEmailScreenProps {
  image: IGatsbyImageData;
  mobileImage: IGatsbyImageData;
  className?: string;
}

const SubmitEmailScreen = ({
  image,
  mobileImage,
  className,
}: SubmitEmailScreenProps): React.ReactElement => {
  const { setCurrentScreen, answers } = useGlobalState();

  const fieldsByName = {
    email: useFormField<string>('', ['required', 'email']),
  } as const;

  async function wait(ms) {
    return new Promise(function (resolve, reject) {
      setTimeout(resolve, ms);
    });
  }

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState, resetState } = useForm(
    {
      fieldsByName,
      onSubmit,
      translateFunction: key => {
        return {
          'form.required_field_error': 'This field is required',
          'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
          'form.network_error': 'Network failed to send your request.',
          'form.unknown_error': 'An unexpected error occured. Please try again later.',
        }[key];
      },
    },
  );

  async function onSubmit() {
    if (process.env.NODE_ENV === 'development') {
      // Code to test form states (should be commented)
      // await wait(2000); // Case loading
      // throw new Error('Got response with status code 400'); // Case unknown error
      // throw new Error('Failed to fetch'); // Case network error
      // setCurrentScreen('result');
      // setTimeout(() => {
      //   resetState();
      // }, 100);
      // return true; // Case success
    }

    const data = {
      email: fieldsByName.email.value!.trim(),
      personality: getPersonalityFromAnswers(answers),
    };

    if (!data.email || !data.personality) {
      throw new Error('Called onSubmit without having all data');
    }

    const respKlaviyo = await fetch('/.netlify/functions/add-profile-to-klaviyo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (respKlaviyo.status !== 200) {
      throw new Error(
        'Got response from add-profile-to-klaviyo with status code ' + respKlaviyo.status,
      );
    }

    const respNetlifyForm = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeFormData({
        'form-name': 'contact',
        ...data,
      }),
    });
    if (respNetlifyForm.status !== 200) {
      throw new Error('Got response from netlify form with status code ' + respNetlifyForm.status);
    }

    if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'email-submit',
        personality: getPersonalityFromAnswers(answers),
      });
    }

    setCurrentScreen('result');
    setTimeout(() => {
      resetState();
    }, 100);
    window.scrollTo(0, 0);
    return true;
  }

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <GatsbyImage alt="" image={image} className={styles.image} />
        </div>
        <div className={styles.mobileImageContainer}>
          <GatsbyImage alt="" image={mobileImage} className={styles.image} />
        </div>
        <div className={styles.textAndInputContainer}>
          <p className={styles.introText}>Before we continue...</p>
          <h2 className={styles.title}>Enter your email to get your results!</h2>
          <div className={styles.formContainer}>
            <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
              <input type="hidden" name="personality" />
              <div className={styles.email}>
                <label
                  className={clsx(styles.label, fieldsByName.email.error && styles.error)}
                  htmlFor="email-input"
                >
                  Email*
                </label>
                <InputField
                  className={clsx(styles.input)}
                  containerClass={styles.inputContainer}
                  type="email"
                  id="email-input"
                  name="email"
                  placeholder="Enter your e-mail here"
                  containerErrorClass={styles.error}
                  helperTextClass={styles.helperText}
                  {...getFieldProps(fieldsByName.email)}
                />
              </div>

              <div className={styles.buttonAndErrorContainer}>
                {renderSubmitButton({
                  labels: {
                    ready: 'Send',
                    submitting: 'Sending',
                    submitted: 'Sent',
                  },
                  childrenAfter: submitState === 'submitting' && (
                    <div className={styles.icon}>
                      <CgSpinner />
                    </div>
                  ),
                  btnClasses: {
                    common: styles.submitButton,
                    ready: styles.formReady,
                    submitting: styles.formSubmitting,
                    submitted: styles.formSubmitted,
                  },
                })}
                {renderFormMessage({
                  styles: {
                    formMessage: styles.formMessage,
                    formMessageSuccess: styles.formMessageSuccess,
                    formMessageError: styles.formMessageError,
                  },
                })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitEmailScreen;
