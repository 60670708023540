import React from 'react';
import { clsx } from '../../utils/utils';
import rightElement from '../../images/frameElement-LightGreen.png';
import leftElement from '../../images/frameElement-DarkGreen.png';
import { Screen } from '../../templates/pages';

import * as styles from './Frame.module.scss';

export interface FrameProps {
  children: any;
  currentScreen: Screen;
  className?: string;
}

const Frame = ({ children, currentScreen, className }: FrameProps): React.ReactElement => {
  return (
    <div
      className={clsx(
        className,
        styles.container,
        currentScreen === 'result' && styles.largerPadding,
      )}
    >
      <div className={styles.rightElementContainer}>
        <img src={rightElement} alt="" />
      </div>
      <div className={styles.leftElementContainer}>
        <img src={leftElement} alt="" />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Frame;
