import { graphql } from 'gatsby';
import React from 'react';
import { replaceSquareBracketsWithSpan } from '../../utils/utils';
import Button from './Button';

import * as styles from './Hero.module.scss';

export const HeroFragment = graphql`
  fragment Hero on SanityLpPersonalityQuizHero {
    title
    subtitle
  }
`;

export interface HeroProps {
  title: string;
  subtitle: string;
  onButtonClick: () => void;
}

const Hero = ({ title, subtitle, onButtonClick }: HeroProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{replaceSquareBracketsWithSpan(subtitle)}</p>
        <Button onClick={onButtonClick} className={styles.button}>
          Start the quiz
        </Button>
      </div>
    </div>
  );
};

export default Hero;
