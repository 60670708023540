import { graphql } from 'gatsby';
import React from 'react';
import { clsx } from '../../utils/utils';
import logoIcon from '../../images/logoIcon.svg';
import Button from './Button';

import * as styles from './CtaSection.module.scss';

export const CtaSectionFragment = graphql`
  fragment CtaSection on SanityLpPersonalityQuizCtaSection {
    text
  }
`;

export interface CtaSectionProps {
  text: string;
  onButtonClick: () => void;
  className?: string;
}

const CtaSection = ({ text, onButtonClick, className }: CtaSectionProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.logoIconContainer}>
        <img src={logoIcon} alt="" />
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.text}>{text}</p>
        <Button secondary onClick={onButtonClick} className={styles.button}>
          Start the quiz
        </Button>
      </div>
    </div>
  );
};

export default CtaSection;
