import React, { useRef } from 'react';
import usePortal from 'react-useportal';
import { clsx } from '../../utils/utils';
import Modal from './Modal';

import * as styles from './ModalOverlay.module.scss';

export interface ModalOverlayProps {
  className?: string;
  onClose: React.MouseEventHandler;
  children: React.ReactNode;
}

const ModalOverlay = ({ className, children, onClose }: ModalOverlayProps): React.ReactElement => {
  const { Portal } = usePortal();

  const skipNextOverlayClick = useRef(false);

  return (
    <Portal>
      <div
        className={clsx(styles.overlay, className)}
        onClick={e => {
          if (skipNextOverlayClick.current) {
            skipNextOverlayClick.current = false;
          } else {
            onClose(e);
          }
        }}
      >
        <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
          <Modal
            onClose={onClose}
            onMouseDown={() => (skipNextOverlayClick.current = true)}
            onMouseUp={() => (skipNextOverlayClick.current = false)}
          >
            {children}
          </Modal>
        </div>
      </div>
    </Portal>
  );
};

export default ModalOverlay;
