import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import {
  FaEnvelopeSquare,
  FaFacebookSquare,
  FaLinkedin,
  FaTimes,
  FaTwitterSquare,
} from 'react-icons/fa';
import { withDataLayer } from '../../utils/utils';

import * as styles from './ShareCard.module.scss';

interface ShareCardProps {
  shareUrl: string;
  onClose: () => void;
}

const ShareCard = ({ shareUrl, onClose }: ShareCardProps): React.ReactElement => {
  return (
    <div className={styles.shareCardContainer}>
      <FaTimes className={styles.closeIcon} onClick={onClose} />
      <span className={styles.shareCardTitle}>Share with your friends</span>
      <div className={styles.buttonsRow}>
        <FacebookShareButton
          url={shareUrl + '?source=facebook'}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'facebook' });
            });
          }}
        >
          <FaFacebookSquare className={styles.icon} />
          <span className={styles.linkSpan}>Facebook</span>
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl + '?source=twitter'}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'twitter' });
            });
          }}
        >
          <FaTwitterSquare className={styles.icon} />
          <span className={styles.linkSpan}>Twitter</span>
        </TwitterShareButton>
        <EmailShareButton
          url={shareUrl + '?source=email'}
          className={styles.linkContainer}
          onClick={(_, link) => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'email' });
            });

            // WARNING: don't remove this line, EmailShareButton has it's own onClick
            // that gets overriden by ours so we need to redo it's code here.
            window.open(link, '_blank');
          }}
        >
          <FaEnvelopeSquare className={styles.icon} />
          <span className={styles.linkSpan}>Email</span>
        </EmailShareButton>
        <LinkedinShareButton
          url={shareUrl + '?source=linkedin'}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'linkedin' });
            });
          }}
        >
          <FaLinkedin className={styles.icon} />
          <span className={styles.linkSpan}>LinkedIn</span>
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default ShareCard;
