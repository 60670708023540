import { graphql, navigate } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import SEO from '../../components/SEO';
import CtaSection, { CtaSectionProps } from '../../components/ui/CtaSection';
import Frame from '../../components/ui/Frame';
import Header from '../../components/ui/Header';
import Hero, { HeroProps } from '../../components/ui/Hero';
import ImageAndTextSection, {
  ImageAndTextSectionProps,
} from '../../components/ui/ImageAndTextSection';
import Layout from '../../components/ui/Layout';
import QuestionScreen from '../../components/ui/QuestionScreen';
import ResultScreen from '../../components/ui/ResultScreen';
import SubmitEmailScreen from '../../components/ui/SubmitEmailScreen';
import { SITE_BASE_URL } from '../../constants';
import { LocalizedSEO } from '../../fragments';
import heroImageDesktopBig from '../../images/HeroImageDesktopBig.png';
import rightElement from '../../images/frameElement-LightGreen.png';
import heroImage from '../../images/heroImage.png';
import heroImageMobile from '../../images/heroImage_mobile.png';
import { useGlobalState } from '../../state/globalStateContext';
import urlJoin, { clsx, getPersonalityFromAnswers } from '../../utils/utils';

import '../../styles/main.scss';
import * as styles from './index.module.scss';

export const query = graphql`
  {
    sanityLpPersonalityQuizQuizConfig {
      questions {
        question
        answers {
          answer
          personality
        }
        image {
          asset {
            gatsbyImageData(
              width: 1400
              height: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 1600
              height: 760
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      personalities {
        ...Personalities
      }
    }
    sanityLpPersonalityQuiz {
      logo {
        asset {
          url
        }
      }
      logoIcon {
        asset {
          url
        }
      }
      bookACallButtonLink
      hero {
        ...Hero
      }
      imageAndTextSection {
        ...ImageAndTextSection
      }
      ctaSection {
        ...CtaSection
      }
      submitEmailScreenImage {
        asset {
          gatsbyImageData(
            width: 1400
            height: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      submitEmailScreenMobileImage {
        asset {
          gatsbyImageData(
            width: 1400
            height: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      seo {
        ...SEOWebsite
      }
    }
  }
`;

export type Screen = 'home' | 'question' | 'submitEmail' | 'result';

export type Personality = 'wallflower' | 'baseCamper' | 'explorer' | 'prosperous';
export type Answer = Personality | 'none';

interface PersonalityInfo {
  name: string;
  text: string;
  videoUrl: string;
  openGraphImage: {
    asset: {
      url: string;
    };
  };
}

interface IndexPageProps {
  pageContext: {
    personality?: Personality;
  };
  data: {
    sanityLpPersonalityQuizQuizConfig: {
      questions: Array<{
        question: string;
        answers: Array<{
          answer: string;
          personality: Answer;
        }>;
        image: {
          asset: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
        mobileImage: {
          asset: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
      }>;
      personalities: {
        wallflower: PersonalityInfo;
        baseCamper: PersonalityInfo;
        explorer: PersonalityInfo;
        prosperous: PersonalityInfo;
      };
    };
    sanityLpPersonalityQuiz: {
      logo: {
        asset: {
          url: string;
        };
      };
      logoIcon: {
        asset: {
          url: string;
        };
      };
      bookACallButtonLink: string;
      hero: HeroProps;
      imageAndTextSection: ImageAndTextSectionProps;
      ctaSection: CtaSectionProps;
      submitEmailScreenImage: {
        asset: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      submitEmailScreenMobileImage: {
        asset: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      seo: LocalizedSEO;
    };
  };
}

let firstVisitWithPersonalitySlug = true;

const IndexPage = ({ pageContext, data }: IndexPageProps): React.ReactElement => {
  const { questions, personalities } = data.sanityLpPersonalityQuizQuizConfig;
  const {
    logo,
    logoIcon,
    bookACallButtonLink,
    hero,
    imageAndTextSection,
    ctaSection,
    submitEmailScreenImage,
    submitEmailScreenMobileImage,
    seo,
  } = data.sanityLpPersonalityQuiz;

  const { currentScreen, setCurrentScreen, answers, setAnswers } = useGlobalState();
  const skipNextUpdateHistory = useRef(false);
  const replaceNextState = useRef(true);

  let chosenPersonalityConfig: PersonalityInfo | null = null;
  let seoProps = {
    lang: 'en',
    title: seo.title,
    shareTitle: seo.shareTitle,
    description: seo.description,
    imageUrl: seo.image.asset.url,
    url: SITE_BASE_URL,
  };

  if (pageContext.personality || currentScreen === 'result') {
    const resultPersonality = pageContext.personality || getPersonalityFromAnswers(answers);
    chosenPersonalityConfig = personalities[resultPersonality];
    seoProps = {
      ...seoProps,
      shareTitle: `I'm ${chosenPersonalityConfig.name} - ${seo.title}`,
      description: chosenPersonalityConfig.text,
      imageUrl: chosenPersonalityConfig.openGraphImage.asset.url,
      url: urlJoin(SITE_BASE_URL, resultPersonality),
    };
  }

  function onPopState(e) {
    e.preventDefault();
    unstable_batchedUpdates(() => {
      if (e.state) {
        skipNextUpdateHistory.current = true;
        setCurrentScreen(e.state.currentScreen || 'home');
        setAnswers(e.state.answers || []);
      }
    });
  }

  function updateHistory(shouldReplaceState?: boolean, url?: string) {
    if (skipNextUpdateHistory.current) {
      skipNextUpdateHistory.current = false;
      return;
    }
    const newState = {
      currentScreen,
      answers,
    };
    // We need this url test because IE is very stupid and its behaviour is
    // different when passing the 3rd parameter as undefined than not passing it.
    if (url) {
      if (shouldReplaceState) {
        window.history.replaceState(newState, '', url);
      } else {
        window.history.pushState(newState, '', url);
      }
    } else {
      if (shouldReplaceState) {
        window.history.replaceState(newState, '');
      } else {
        window.history.pushState(newState, '');
      }
    }
  }

  useEffect(() => {
    if (pageContext.personality) {
      if (firstVisitWithPersonalitySlug) {
        firstVisitWithPersonalitySlug = false;
        const newState = { currentScreen: 'home', answers: [], selectedGender: null };
        navigate('/', {
          state: newState,
        });
      } else {
        window.history.back();
      }
    }
    window.onpopstate = onPopState;
  }, []);

  useEffect(() => {
    updateHistory(replaceNextState.current);
    if (replaceNextState.current) {
      replaceNextState.current = false;
    }
  }, [currentScreen, answers.join(',')]);

  const frameRef = useRef<HTMLDivElement | null>(null);

  return (
    <Layout
      hideLayoutHeader={currentScreen === 'home' && true}
      headerBackgroundColor={'white'}
      headerBookACallLink={bookACallButtonLink}
      logoUrl={logo.asset.url}
      logoIconUrl={logoIcon.asset.url}
    >
      <SEO {...seoProps} />
      {currentScreen === 'home' && (
        <>
          <div className={styles.desktopHeroAndHeaderContainer}>
            <Header
              backgroundColor="gradient"
              bookACallLink={bookACallButtonLink}
              logoUrl={logo.asset.url}
              logoIconUrl={logoIcon.asset.url}
            />
            <Hero
              {...hero}
              onButtonClick={() => {
                setCurrentScreen('question');
                window.scrollTo(0, 0);
              }}
            />
            <div className={clsx(styles.imageContainer, styles.heroDesktopImageContainer)}>
              <img src={heroImage} alt="" />
            </div>
            <div className={styles.heroMobileImageContainer}>
              <img src={heroImageMobile} alt="" />
            </div>
            <div className={styles.heroBigDesktopImageContainer}>
              <img src={heroImageDesktopBig} alt="" />
            </div>
            <div className={styles.rightElementContainer}>
              <img src={rightElement} alt="" />
            </div>
          </div>
          <ImageAndTextSection {...imageAndTextSection} />
          <CtaSection
            {...ctaSection}
            onButtonClick={() => {
              setCurrentScreen('question');
              window.scrollTo(0, 0);
            }}
          />
        </>
      )}
      <>
        {(currentScreen === 'question' || currentScreen === 'submitEmail') && (
          <div className={styles.mobileImageContainer}>
            <GatsbyImage
              alt=""
              image={
                currentScreen === 'question'
                  ? questions[answers.length].mobileImage.asset.gatsbyImageData
                  : submitEmailScreenMobileImage.asset.gatsbyImageData
              }
              className={styles.image}
            />
          </div>
        )}
        <div ref={frameRef} style={{ display: currentScreen !== 'home' ? undefined : 'none' }}>
          <Frame currentScreen={currentScreen}>
            {currentScreen === 'question' && (
              <QuestionScreen
                gatsbyImageAsset={questions[answers.length].image.asset.gatsbyImageData}
                mobileGatsbyImageAsset={questions[answers.length].mobileImage.asset.gatsbyImageData}
                currentQuestionNumber={answers.length + 1}
                totalQuestionsNumber={questions.length}
                question={questions[answers.length].question}
                answersWithAnswerText={questions[answers.length].answers.map(
                  ({ personality: answer, answer: answerText }) => [answer, answerText],
                )}
                onAnswerPick={answer => {
                  const newAnswers = [...answers, answer];
                  unstable_batchedUpdates(() => {
                    setAnswers(newAnswers);
                    if (newAnswers.length >= questions.length) {
                      setCurrentScreen('submitEmail');
                    }
                    window.scrollTo(
                      0,
                      frameRef.current
                        ? frameRef.current.getBoundingClientRect().y + window.scrollY
                        : 0,
                    );
                  });
                }}
              />
            )}
            <div style={{ display: currentScreen === 'submitEmail' ? undefined : 'none' }}>
              <SubmitEmailScreen
                image={submitEmailScreenImage.asset.gatsbyImageData}
                mobileImage={submitEmailScreenMobileImage.asset.gatsbyImageData}
              />
            </div>
            {currentScreen === 'result' && (
              <ResultScreen
                bookACallButtonLink={bookACallButtonLink}
                title={chosenPersonalityConfig!.name}
                text={chosenPersonalityConfig!.text}
                videoUrl={chosenPersonalityConfig!.videoUrl}
              />
            )}
          </Frame>
        </div>
      </>
    </Layout>
  );
};

export default IndexPage;
