// extracted by mini-css-extract-plugin
export var answer = "QuestionScreen-module--answer--b133d";
export var answersContainer = "QuestionScreen-module--answersContainer--1ed61";
export var container = "QuestionScreen-module--container--e94bb";
export var contentContainer = "QuestionScreen-module--contentContainer--03b94";
export var image = "QuestionScreen-module--image--75419";
export var imageContainer = "QuestionScreen-module--imageContainer--11832";
export var mobileImageContainer = "QuestionScreen-module--mobileImageContainer--88a12";
export var question = "QuestionScreen-module--question--a4991";
export var questionContainer = "QuestionScreen-module--questionContainer--3f734";
export var questionsNumber = "QuestionScreen-module--questionsNumber--8c87e";