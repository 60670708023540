import React, { useState } from 'react';
import urlJoin, { clsx, getPersonalityFromAnswers, withDataLayer } from '../../utils/utils';
import Button from './Button';
import ModalOverlay from './ModalOverlay';
import ShareCard from './ShareCard';
import Video from './Video';

import * as styles from './ResultScreen.module.scss';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { SITE_BASE_URL } from '../../constants';
import { useGlobalState } from '../../state/globalStateContext';

export interface ResultScreenProps {
  title: string;
  text: string;
  videoUrl: string;
  bookACallButtonLink: string;
  className?: string;
}

const ResultScreen = ({
  title,
  text,
  videoUrl,
  bookACallButtonLink,
  className,
}: ResultScreenProps): React.ReactElement => {
  const { answers } = useGlobalState();
  const [isShareCardVisible, setIsShareCardVisible] = useState(false);

  const resultPersonality = getPersonalityFromAnswers(answers);
  const shareUrl = urlJoin(SITE_BASE_URL, resultPersonality);

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <span className={styles.introPhrase}>Your personality type is...</span>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
          <div className={styles.buttonsContainer}>
            <a
              href={bookACallButtonLink}
              target="_blank"
              rel="noreferrer"
              tabIndex={-1}
              className={styles.button}
            >
              <Button>Book a call</Button>
            </a>
            <Button
              secondary
              onClick={() => {
                setIsShareCardVisible(true);
                withDataLayer(dataLayer => {
                  dataLayer.push({ event: 'open-share-card' });
                });
              }}
              className={styles.button}
            >
              Share results
            </Button>
          </div>
        </div>
        <div className={styles.videoContainer}>
          <Video url={videoUrl} />
        </div>
      </div>
      {isShareCardVisible && (
        <ModalOverlay
          onClose={() => setIsShareCardVisible(false)}
          className={clsx(styles.shareModal)}
        >
          <ShareCard shareUrl={shareUrl} onClose={() => setIsShareCardVisible(false)} />
        </ModalOverlay>
      )}
    </div>
  );
};

export default ResultScreen;
